import { Box, Grid, Typography } from "@mui/material"
import { Fade, Slide } from 'react-awesome-reveal';

export function Section3() {
    return (
        <Box mb={25}>
            <Box>
                <Slide direction="up">
                    <Typography variant="h3">
                        <span className="_text-color" style={{ display: 'block' }}>How It Works</span>
                    </Typography>
                </Slide>
            </Box>

            <Grid container mt={3}>
                <Grid item xs={12} sm={6} sx={{ alignSelf: 'center' }}>
                    <Fade direction="up">
                        <Box pr={4}>
                            {/* <Typography variant="h6">Internal allocations</Typography>
                            <Typography fontSize=".9rem" mt={3}>
                                Our internal portfolio brings together
                                <span className="_text-color"> various algorithmic models </span>
                                into a single robust trading mechanism.
                                We harness insights from unconventional data streams like
                                <span className="_text-color"> blockchain and news, as well as price-based models, </span>
                                equipping it with a comprehensive understanding of the market.
                                Our infrastructure allows us to
                                <span className="_text-color">trade with different frequencies</span> from milliseconds to days and across different markets: spot, futures, options.
                            </Typography>

                            <Button variant="outlined" sx={{ fontSize: '.8rem', px: 5, my: 2, color: 'white', textTransform: 'initial' }}>
                                Learn More
                            </Button> */}

                            <Box>
                                <Box><h3>Step 1: Have Trading Ideas?</h3></Box>
                                <Box>Inspire profitable trades with insightful and data-driven trading ideas.</Box>
                            </Box>
                            <Box mt={5}>
                                <Box><h3>Step 2: Build Your Strategy using Propdesk</h3></Box>
                                <Box>Our team of experts will build your personal strategies and algorithms.</Box>
                            </Box>
                            <Box mt={5}>
                                <Box><h3>Step 3: Debug and Test with live and simulated market data</h3></Box>
                                <Box>Refine strategies seamlessly using live and simulated market data for effective debugging and testing.</Box>
                            </Box>
                            <Box mt={5}>
                                <Box><h3>Step 4: Fine tune your strategy, Callibrate parameter and retest</h3></Box>
                                <Box>Optimize strategies by fine-tuning, calibrating parameters, and retesting for enhanced performance.</Box>
                            </Box>
                            <Box mt={5}>
                                <Box><h3>Step 5: Execute (Go Live!)</h3></Box>
                                <Box>Execute your refined strategy confidently with seamless transition to live trading.</Box>
                            </Box>
                        </Box>
                    </Fade>

                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box>
                        <img src="./key_features.png" height={500} width={550} alt="key-features" />

                        {/* <video
                            style={{ height: '100%', objectFit: 'cover', objectPosition: 'center', pointerEvents: 'none', userSelect: 'none', width: '100%' }}
                            src="./key_features.mp4"
                            type="video/mp4"
                            preload="auto"
                            playsInline="true"
                            loop="true"
                            autoPlay="true"
                        ></video> */}
                    </Box>
                </Grid>
            </Grid>

        </Box>
    )
}