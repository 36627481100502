import { Box, Typography } from "@mui/material"

export function Section1() {
    return (
        <Box mb={30} mt={20}>
            <Box 
                style={{
                    lineHeight: '85%',
                    fontFamily: 'Helvetica Now Display',
                    fontSize: '3rem'
                }}
            >
                <Box>
                    <Box>
                        <Typography variant="h2" lineHeight={0.9}>
                            <span style={{ display: 'block' }}>Eliminate emotions</span>
                            <span className="_text-color">Automate trade</span> with <br />
                            <span className="_text-color">math and probability.</span>
                        </Typography>
                    </Box>
                </Box>
                <Box style={{ fontSize: '40%', color: '#c4dbe0', marginTop: 20 }}>
                    <span style={{ borderTop: '1px solid #c4dbe0', paddingTop: 10 }}>
                        We turn your Quantitative thoughts into actionable insights.
                    </span>
                </Box>
            </Box>

        </Box>
    )
}