import { Box, Container, Button } from "@mui/material"

export function Header() {
    return (
        <Box sx={{ position: "sticky", top: 0, bgcolor: "rgba(0,0,0,0.3)" }}>
            <Container>
                <Box sx={{ display: "flex", justifyContent: "space-between", py: 2 }}>
                    <Box display="flex" alignItems="center">
                        <img src="./Logo/3.png" height={80} width={120} alt="propdesk-logo" />
                    </Box>

                    <Box display="flex" alignItems="center">
                        <Button
                            variant="contained"
                            onClick={() => window.open("https://terminal.propdesk.in/", "_blank")}
                            sx={{
                                height: 50,
                                backgroundColor: "white",
                                color: "black",
                                "&:hover": {
                                    backgroundColor: "#c9c9c9"
                                }
                            }}
                        >
                            Algo Terminal
                        </Button>
                    </Box>

                    {/* <Box>
                        <Typography>PROPDESK</Typography>
                    </Box> */}

                    {/* <Box display="flex">
                        {
                            ["Home", "Asset Managers", "Careers"].map(el => (
                                <Box key={el} pl={3}>
                                    <Typography>{el}</Typography>
                                </Box>
                            ))
                        }
                    </Box> */}
                </Box>
            </Container>

        </Box>
    )
}