// import Fluid from 'fluid-canvas';
import { useEffect } from 'react';
import { Section1 } from './sections/Section1';
import { Section2 } from './sections/Section2';
import { Section3 } from './sections/Section3';
import { Section4 } from './sections/Section4';
import { Section5 } from './sections/Section5';
import { ContactUs } from './sections/ContactUs';
import { Footer } from './sections/Footer';
import { Container } from '@mui/material';
import { Header } from './sections/Header';

const Sections = [
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    ContactUs,
    Footer
]

export function Main() {


    useEffect(() => {
        function _err(fun){
            fun.catch(function (error) {
                console.log("Chrome cannot play sound without user interaction first", error)
            });
        }
        function playVisibleVideos() {
            document.querySelectorAll("video").forEach(video => {
                try{
                    elementIsVisible(video) ? _err(video.play()) : _err(video.pause())
                }catch(e){}
            });
        }

        function elementIsVisible(el) {
            let rect = el.getBoundingClientRect();
            return (rect.bottom >= 0 && rect.right >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) && rect.left <= (window.innerWidth || document.documentElement.clientWidth));
        }

        function onScroll(){
            clearTimeout(playVisibleVideosTimeout);
            playVisibleVideosTimeout = setTimeout(playVisibleVideos, 100);
        }

        let playVisibleVideosTimeout;
        const _timer = setTimeout(()=>{
            window.addEventListener("scroll", onScroll, { passive: true });
            window.addEventListener("resize", playVisibleVideos);
            window.addEventListener("DOMContentLoaded", playVisibleVideos);
        }, 1000)
        return () => {
            clearTimeout(_timer)
            window.removeEventListener("scroll", onScroll, { passive: true });
            window.removeEventListener("resize", playVisibleVideos)
            window.removeEventListener("DOMContentLoaded", playVisibleVideos);

        }
    }, [])

    return (
       <main>
        <Header />
         <Container>
            {
                Sections.map((Component, i) => <Component key={i} />)
            }
        </Container>
       </main>
    )
}