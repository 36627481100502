import { Box, Grid, Typography } from "@mui/material"
import { Fade, Slide } from 'react-awesome-reveal';

const cardList = [
    {
        value: 'Create Durable Trading Strategies',
        title: 'Propdesk'
    },
    {
        value: 'Paper Trade. Backtest. Debug.',
        title: 'Risk Management'
    },
    {
        value: 'Access Anytime & Anywhere',
        title: 'Could Management'
    },
    {
        value: 'Hassle-Free Maintenance',
        title: 'Maintain Focus'
    }
]


export function Section2() {
    return (
        <Box mb={25}>
            <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12} sm={9}>
                    <Box>
                        <Box style={{ textAlign: 'center' }}>
                            <Typography variant="h3">About</Typography>
                        </Box>

                        <Box mt={5}>
                        {/* AlgoQuant is committed to pioneering algorithmic trading solutions.  */}

                            <Fade direction="up">
                                <Box style={{ fontSize: '1rem', textAlign: 'center' }}>
                                    <span className="_text-color">Propdesk </span>
                                    is committed to pioneering
                                    <span className="_text-color"> algorithmic trading solutions.</span>
                                    <span>
                                        With a seasoned team boasting extensive market acumen, we consistently innovate on the forefront of trading strategies. 
                                        Our approach encompasses both 
                                    </span>
                                    <span className="_text-color"> proprietary product development </span> 
                                    <span>and strategic collaborations with external partners, 
                                        ensuring optimal outcomes. Through ongoing evolution and agile adaptation to market dynamics, we persistently enhance 
                                        our proficiency and excel in competitive environments.
                                    </span>
                                </Box>
                            </Fade>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Grid my={5} container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <Box className="_text-color">
                    <Typography fontSize={20}>
                        <hr/>
                            Take advantage of propdesk with our all-in-one automated trading toolset.
                        <hr/>
                    </Typography>
                </Box>
            </Grid>

            <Grid
                container
                rowSpacing={1}
                mb={3}
                mt={1}
            >
                {
                    cardList.map((card, i) => {
                        return (
                            <Grid item key={i} xs={12} sm={6}>
                                <Slide direction="up">
                                    <Fade>
                                        <Box sx={{ m: 1, p: 3, border: '.0694444444rem solid rgba(196,219,224,.4)' }}>
                                            <Box sx={{ mt: 3 }}>
                                                <Slide direction="up">
                                                    <Typography style={{ color: '#c4dbe0' }} fontSize='1.2rem'>{card.title}</Typography>
                                                </Slide>
                                            </Box>
                                            
                                            <Box>
                                                <Slide direction="up">
                                                    <Typography color='#c4dbe0' fontSize='1.8rem' fontWeight={600}>{card.value}</Typography>
                                                </Slide>
                                            </Box>
                                        </Box>
                                    </Fade>
                                </Slide>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}