import { Box, Typography } from "@mui/material"

export function Footer() {
    return (
        <footer>
            <Box>
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', border: "0.2px solid #cee1e5b3", py: 2, borderLeft: 'unset', borderRight: 'unset' }}>
                    <Box>
                        <Typography>ALGOQUANT</Typography>
                    </Box>
                    <Box display="flex">
                        {
                            ["Home", "Asset Managers", "Careers"].map(el => (
                                <Box key={el} pl={3}>
                                    <Typography>{el}</Typography>
                                </Box>
                            ))
                        }
                    </Box>
                </Box> */}

                {/* <Box sx={{ borderBottom: "0.2px solid #cee1e5b3", py: 2 }}>
                    <Typography fontSize=".7rem" color="#cee1e5b3">
                        Please note that AlgoQuant, LTD is a proprietary trading firm and is not soliciting customers or customer relationships by means of this website or otherwise. Furthermore, AlgoQuant, LTD is not offering any securities or other financial products for sale nor is it soliciting any transactions or investment through this website. The information on this website is not intended to constitute investment, legal or tax advice and may not be relied upon as such by any person. AlgoQuant, LTD maintains this website for informational purposes only and undertakes no obligation regarding the information contained herein
                    </Typography>
                </Box> */}

                <Box sx={{ 
                    mt: 2, 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    border: "0.2px solid #cee1e5b3", 
                    py: 2,
                    borderLeft: 'unset', 
                    borderRight: 'unset',
                    borderBottom: 'unset'
                }}>
                    {/* <Box>
                        <Typography variant="h6">
                            Contact Us
                            <span style={{ display: 'block', fontSize: '.7rem' }}>info@gmail.com</span>
                        </Typography>
                    </Box> */}
                    <Box>
                        <Typography fontSize=".8rem">
                            2024 Propdesk Ltd.
                        </Typography>
                    </Box>
                    {/* <Box>
                        <Typography fontSize=".8rem">
                            Privacy and Cookies Policy
                        </Typography>
                    </Box> */}
                </Box>
            </Box>
        </footer>
    )
}