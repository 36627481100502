import { Box, Grid, Typography } from "@mui/material"
import { Fade, Slide } from 'react-awesome-reveal';

const solutions = [
    {
        value: 'Traders',
        desc: 'Suitable for a wide range of trading styles including scalping, investing, intraday, positional, manual, discretionary, price action, system-based, algorithmic and quantitative trading.'
    },
    {
        value: 'Retail Brokers',
        desc: 'Our algo trading software offers seamless integration with retail brokers, empowering users to execute automated trades efficiently and capitalize on market opportunities effortlessly.'
    },
    {
        value: 'Institutional Brokers',
        desc: 'Our algorithmic trading platform effortlessly links with institutional brokers, furnishing advanced tools to execute intricate trading strategies swiftly, accurately, and reliably, catering to institutional investors needs.'
    },
    {
        value: 'Proprietary Desk',
        desc: 'Our algo trading software optimizes proprietary desk operations, offering advanced algorithms for strategic trading, risk management, and maximizing returns in dynamic market environments with precision and agility.'
    }
]

export function Section5() {
    return (
        <Box mb={30}>
            <Box>
                <Box>
                    <Fade direction="up">
                        <Typography variant="h3">
                            Our
                            <span className="_text-color" style={{ display: 'block' }}>Solutions For</span>
                        </Typography>
                    </Fade>
                </Box>

                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item xs={12} sm={3}>
                        <Fade direction="left">
                        <Box>
                            <img
                                style={{
                                    width: '700px',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                                src="https://algoquant.io/assets/img/server-sequence/webp/00001.webp" 
                                alt="solutions"
                            />
                        </Box>
                        </Fade>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                        {
                            solutions.map((card, i) => {
                                return (
                                    <Box mt={3}>
                                        <Slide direction="up">
                                            <Fade>
                                                <Box sx={{ m: 1, p: 3, border: '.0694444444rem solid rgba(196,219,224,.4)' }}>
                                                    <Box>
                                                        <Slide direction="up">
                                                            <Typography color='#c4dbe0' fontSize='1.6rem' fontWeight={600}>{card.value}</Typography>
                                                        </Slide>
                                                    </Box>

                                                    <Box sx={{ mt: 1.5 }}>
                                                        <Slide direction="up">
                                                            <Typography style={{ color: '#c4dbe0' }} fontSize='1.2rem'>{card.desc}</Typography>
                                                        </Slide>
                                                    </Box>
                                                </Box>
                                            </Fade>
                                        </Slide>
                                    </Box>
                                )
                            })
                        }
                    </Grid>

                    {/* <Grid item xs={12} sm={8}>
                        <Fade direction="up">
                            <Box>
                                <Typography variant="h6">1. Data</Typography>
                                <Typography fontSize="1rem" mt={2}>
                                    Our trading systems are designed for 
                                    <span className="_text-color">seamless strategy integration, backtesting and optimal execution. </span> 
                                    The technical team takes responsibility for the integration with the trading platforms, and also provides our specialists with the necessary tools, 
                                    <span className="_text-color">allowing quants to focus on the development of signal modules.</span> 
                                    The <span className="_text-color">reliability</span> 
                                    of our trading systems is evidenced by the high volume of orders they have successfully managed over the years.
                                </Typography>
                            </Box>
                        </Fade>
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
    )
}