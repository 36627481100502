import { Box, Grid, Typography } from "@mui/material"
import { Fade, Slide } from 'react-awesome-reveal';

const keyFeatures = [
    {
        value: '100% Automate gruntwork',
        desc: 'Fully automate manual tasks, streamlining trading operations for efficiency and accuracy.'
    },
    {
        value: 'Scalable Quantitative Desk',
        desc: 'Efficiently manage quantitative trading strategies, adaptable to various market conditions and volumes.'
    },
    {
        value: 'Improve your edge',
        desc: 'Enhance trading advantages through data analysis, strategy optimization, and risk management techniques.'
    },
    {
        value: 'Speed up your processes',
        desc: 'Accelerate trading workflows for swift execution and competitive advantage in fast-paced markets.'
    },
    {
        value: 'Improve trade accuracy',
        desc: 'Enhance trade precision through advanced algorithms and real-time data analysis for optimal decision-making.'
    },
    {
        value: 'Auto Hedged Positions / Delta Neutral',
        desc: 'Automate hedging strategies to maintain delta neutrality, minimizing directional risk in portfolio positions.'
    },
    {
        value: 'Third Party Decision System Integration',
        desc: 'Seamlessly integrate external decision systems for enhanced trading insights and informed decision-making.'
    },
    {
        value: 'Client Management & Multiple API',
        desc: 'Efficiently manage clients and utilize multiple APIs for diverse trading strategies and customization.'
    },
    {
        value: 'Medium Frequency Trading / Basket Trading',
        desc: 'Execute medium-frequency trades and basket trading strategies for diversified portfolio management and optimized returns.'
    }
]

export function Section4() {
    return (
        <Box mb={25}>
            <Box sx={{ textAlign: 'center' }}>
                <Grid container sx={{ alignItem: 'center', justifyContent: 'center' }}>
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <Fade direction="up">
                                <Typography variant="h3">Key Features</Typography>
                            </Fade>
                            <Fade direction="up">
                                {/* <Typography mt={2} fontSize=".9rem">
                                    A fully <span className="_text-color">remote team </span>
                                    collaborates seamlessly from different countries,
                                    <span className="_text-color">ensuring constant interaction with the markets. </span>
                                    Our technical infrastructure <span className="_text-color">minimizes latency</span> and enables
                                    <span className="_text-color">reliable connections </span> to exchanges.
                                </Typography> */}
                            </Fade>
                        </Box>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Box>
                            <video style={{ height: '100%', width: '100%', userSelect: 'none', pointerEvent: 'none' }} preload="auto" playsInline loop autoPlay>
                                <source src="https://algoquant.io/static/media/video.39aa36a8a1525cef92cb.webm" />
                                <source src="https://algoquant.io/static/media/video.d9c3920ed1418822203f.mp4" />
                            </video>
                        </Box>
                    </Grid> */}

                    <Grid
                        container
                        rowSpacing={1}
                        mb={3}
                        mt={5}
                        sx={{ alignItem: 'center', justifyContent: 'center' }}
                    >
                        {
                            keyFeatures.map((card, i) => {
                                return (
                                    <Grid item key={i} xs={12} sm={6}>
                                        <Slide direction="up">
                                            <Fade>
                                                <Box sx={{ m: 1, p: 3, border: '.0694444444rem solid rgba(196,219,224,.4)' }}>
                                                    <Box>
                                                        <Slide direction="up">
                                                            <Typography color='#c4dbe0' fontSize='1.6rem' fontWeight={600}>{card.value}</Typography>
                                                        </Slide>
                                                    </Box>

                                                    <Box sx={{ mt: 3 }}>
                                                        <Slide direction="up">
                                                            <Typography style={{ color: '#c4dbe0' }} fontSize='1.2rem'>{card.desc}</Typography>
                                                        </Slide>
                                                    </Box>
                                                </Box>
                                            </Fade>
                                        </Slide>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>


            </Box>
        </Box>
    )
}