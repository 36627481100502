import { Box, Grid, Typography } from "@mui/material"
import { Fade } from "react-awesome-reveal"
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import EmailIcon from '@mui/icons-material/Email';

export function ContactUs() {

    return (
        <Box mb={25}>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Box>
                        <Typography variant="h3">Contact <span className="_text-color">Us</span></Typography>

                        <Typography my={3}>We welcome your inquiries. Reach out and we'll be in touch soon!</Typography>

                        <Box>
                            <Box display="flex">
                                <EmailIcon />
                                <Typography ml={1}>Email address: </Typography> 
                                <Typography fontWeight={700} ml={1}>vinit@propdesk.in</Typography>
                            </Box>

                            <Box display="flex" mt={1}>
                                <StayCurrentPortraitIcon />
                                <Typography ml={1}>Mobile: </Typography> 
                                <Typography fontWeight={700} ml={1}>+91 93751 92000</Typography>
                            </Box>
                        </Box>

                        {/* <Button variant="outlined" sx={{ color: 'white', textTransform: 'initial', px: 3, fontSize: '.7rem' }}>
                            Contact Us
                        </Button> */}
                    </Box>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <Box textAlign="center" mt={5}>
                        <Fade direction="up">
                            <img style={{ width: 80 }} src="https://algoquant.io/static/media/cube_3.865bc32869e051c7e607.webp" alt="cube-1" />
                        </Fade>
                        <Box display="flex" mt={-5}>
                            <Fade direction="up">
                                <img style={{ width: 200 }} src="https://algoquant.io/static/media/cube_1.1792aa133649f7ddb306.webp" alt="cube-2" />
                            </Fade>
                            <Fade direction="up">
                                <img style={{ width: 170, marginTop: 30 }} src="https://algoquant.io/static/media/cube_2.aacdf4df086b08ca0123.webp" alt="cube-3" />
                            </Fade>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}